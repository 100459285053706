<template>
  <div id="personal_home">
  </div>
</template>

<script>
export default {
  name: 'NETVSPersonalHome',
  mounted() {
    let target = '/home'
    if (this.$store.state.default_login_page) {
      target = this.$store.state.default_login_page
    }
    if (target === '/') {
      target = '/home'
    }
    this.$router.push({path: target})
  }
}
</script>
